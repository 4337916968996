.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\A0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-ad {
  background-image: url(/static/media/ad.4fc7c4c1.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(/static/media/ad.486d25a7.svg);
}
.flag-icon-ae {
  background-image: url(/static/media/ae.9fd1fcbf.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(/static/media/ae.7847726d.svg);
}
.flag-icon-af {
  background-image: url(/static/media/af.b040d017.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(/static/media/af.26d0dcc7.svg);
}
.flag-icon-ag {
  background-image: url(/static/media/ag.5c33e55d.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(/static/media/ag.53a60086.svg);
}
.flag-icon-ai {
  background-image: url(/static/media/ai.2bb08d7d.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(/static/media/ai.7445e66f.svg);
}
.flag-icon-al {
  background-image: url(/static/media/al.01225beb.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(/static/media/al.633e8642.svg);
}
.flag-icon-am {
  background-image: url(/static/media/am.06509258.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(/static/media/am.c86a9e16.svg);
}
.flag-icon-ao {
  background-image: url(/static/media/ao.4e70ca93.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(/static/media/ao.8484ac5a.svg);
}
.flag-icon-aq {
  background-image: url(/static/media/aq.b95845ac.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(/static/media/aq.c61dd421.svg);
}
.flag-icon-ar {
  background-image: url(/static/media/ar.4d01e578.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(/static/media/ar.cab4b98a.svg);
}
.flag-icon-as {
  background-image: url(/static/media/as.e753c5e9.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(/static/media/as.83064c6f.svg);
}
.flag-icon-at {
  background-image: url(/static/media/at.7824152b.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(/static/media/at.dc2457a2.svg);
}
.flag-icon-au {
  background-image: url(/static/media/au.37a54427.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(/static/media/au.da96b2e8.svg);
}
.flag-icon-aw {
  background-image: url(/static/media/aw.29aeb3f9.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(/static/media/aw.f159ec16.svg);
}
.flag-icon-ax {
  background-image: url(/static/media/ax.1aaab703.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(/static/media/ax.fdd00c43.svg);
}
.flag-icon-az {
  background-image: url(/static/media/az.451284ce.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(/static/media/az.0b4258df.svg);
}
.flag-icon-ba {
  background-image: url(/static/media/ba.71010dff.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(/static/media/ba.a9dbadd7.svg);
}
.flag-icon-bb {
  background-image: url(/static/media/bb.7bd8b0ac.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(/static/media/bb.e08360ac.svg);
}
.flag-icon-bd {
  background-image: url(/static/media/bd.5102bab0.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(/static/media/bd.c4a1485f.svg);
}
.flag-icon-be {
  background-image: url(/static/media/be.27d8ca49.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(/static/media/be.f1e78c8b.svg);
}
.flag-icon-bf {
  background-image: url(/static/media/bf.9a958401.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(/static/media/bf.48eb94de.svg);
}
.flag-icon-bg {
  background-image: url(/static/media/bg.3d762564.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(/static/media/bg.7163fe76.svg);
}
.flag-icon-bh {
  background-image: url(/static/media/bh.392927ca.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(/static/media/bh.290519a3.svg);
}
.flag-icon-bi {
  background-image: url(/static/media/bi.9b802cca.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(/static/media/bi.3e29b35c.svg);
}
.flag-icon-bj {
  background-image: url(/static/media/bj.148de921.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(/static/media/bj.b6387659.svg);
}
.flag-icon-bl {
  background-image: url(/static/media/bl.38e27b68.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(/static/media/bl.4d724b8e.svg);
}
.flag-icon-bm {
  background-image: url(/static/media/bm.ca26b6f5.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(/static/media/bm.a523291d.svg);
}
.flag-icon-bn {
  background-image: url(/static/media/bn.80c35c4c.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(/static/media/bn.4f010489.svg);
}
.flag-icon-bo {
  background-image: url(/static/media/bo.c83fd441.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(/static/media/bo.cd93219f.svg);
}
.flag-icon-bq {
  background-image: url(/static/media/bq.b551016f.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(/static/media/bq.d6da2e84.svg);
}
.flag-icon-br {
  background-image: url(/static/media/br.c834cb5b.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(/static/media/br.a82efeaf.svg);
}
.flag-icon-bs {
  background-image: url(/static/media/bs.910d4bd0.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(/static/media/bs.6fe877e1.svg);
}
.flag-icon-bt {
  background-image: url(/static/media/bt.acfa822b.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(/static/media/bt.e502aa33.svg);
}
.flag-icon-bv {
  background-image: url(/static/media/bv.07434a84.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(/static/media/bv.b70ab2f2.svg);
}
.flag-icon-bw {
  background-image: url(/static/media/bw.d1585fdf.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(/static/media/bw.d9e5e45f.svg);
}
.flag-icon-by {
  background-image: url(/static/media/by.26a195de.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(/static/media/by.80b2d2dd.svg);
}
.flag-icon-bz {
  background-image: url(/static/media/bz.e7e7013d.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(/static/media/bz.510d2519.svg);
}
.flag-icon-ca {
  background-image: url(/static/media/ca.ce143108.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(/static/media/ca.5916ba94.svg);
}
.flag-icon-cc {
  background-image: url(/static/media/cc.ecc0e321.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(/static/media/cc.722f0576.svg);
}
.flag-icon-cd {
  background-image: url(/static/media/cd.cd346cdc.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(/static/media/cd.b43f872e.svg);
}
.flag-icon-cf {
  background-image: url(/static/media/cf.667c7a42.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(/static/media/cf.1bc217dc.svg);
}
.flag-icon-cg {
  background-image: url(/static/media/cg.c8c05bfe.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(/static/media/cg.8373836c.svg);
}
.flag-icon-ch {
  background-image: url(/static/media/ch.9c26f60a.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(/static/media/ch.252c409b.svg);
}
.flag-icon-ci {
  background-image: url(/static/media/ci.d939dcac.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(/static/media/ci.26a62321.svg);
}
.flag-icon-ck {
  background-image: url(/static/media/ck.bd3a061c.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(/static/media/ck.d66de29c.svg);
}
.flag-icon-cl {
  background-image: url(/static/media/cl.7709f09f.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(/static/media/cl.6d63ff70.svg);
}
.flag-icon-cm {
  background-image: url(/static/media/cm.5799ad4c.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(/static/media/cm.c972441e.svg);
}
.flag-icon-cn {
  background-image: url(/static/media/cn.02c229de.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(/static/media/cn.a94c9394.svg);
}
.flag-icon-co {
  background-image: url(/static/media/co.3b252a1a.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(/static/media/co.41244c20.svg);
}
.flag-icon-cr {
  background-image: url(/static/media/cr.7b4ebd50.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(/static/media/cr.657d7dbc.svg);
}
.flag-icon-cu {
  background-image: url(/static/media/cu.ff754a33.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(/static/media/cu.0b42edab.svg);
}
.flag-icon-cv {
  background-image: url(/static/media/cv.b50df3fb.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(/static/media/cv.20a8cfff.svg);
}
.flag-icon-cw {
  background-image: url(/static/media/cw.6c845a30.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(/static/media/cw.a5487f56.svg);
}
.flag-icon-cx {
  background-image: url(/static/media/cx.6f47bd14.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(/static/media/cx.9faec396.svg);
}
.flag-icon-cy {
  background-image: url(/static/media/cy.f0e26651.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(/static/media/cy.1c8c0511.svg);
}
.flag-icon-cz {
  background-image: url(/static/media/cz.052ec527.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(/static/media/cz.49044310.svg);
}
.flag-icon-de {
  background-image: url(/static/media/de.3e726c2b.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(/static/media/de.4d7bac3b.svg);
}
.flag-icon-dj {
  background-image: url(/static/media/dj.3cf620d9.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(/static/media/dj.0c386d22.svg);
}
.flag-icon-dk {
  background-image: url(/static/media/dk.eb1416e0.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(/static/media/dk.d046fb5b.svg);
}
.flag-icon-dm {
  background-image: url(/static/media/dm.2910f70a.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(/static/media/dm.5966a127.svg);
}
.flag-icon-do {
  background-image: url(/static/media/do.06e1cfe3.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(/static/media/do.e31ff1fd.svg);
}
.flag-icon-dz {
  background-image: url(/static/media/dz.7c2261f0.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(/static/media/dz.dea7ef63.svg);
}
.flag-icon-ec {
  background-image: url(/static/media/ec.a12f3b49.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(/static/media/ec.e1ea3417.svg);
}
.flag-icon-ee {
  background-image: url(/static/media/ee.6088c9ce.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(/static/media/ee.9e932a62.svg);
}
.flag-icon-eg {
  background-image: url(/static/media/eg.c39a7f7e.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(/static/media/eg.4259fc9e.svg);
}
.flag-icon-eh {
  background-image: url(/static/media/eh.61674b9f.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(/static/media/eh.7af60670.svg);
}
.flag-icon-er {
  background-image: url(/static/media/er.c9799558.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(/static/media/er.976db2b7.svg);
}
.flag-icon-es {
  background-image: url(/static/media/es.cc1b41b0.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(/static/media/es.d18de46b.svg);
}
.flag-icon-et {
  background-image: url(/static/media/et.919059a4.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(/static/media/et.2c1adbb5.svg);
}
.flag-icon-fi {
  background-image: url(/static/media/fi.2649533e.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(/static/media/fi.b48413be.svg);
}
.flag-icon-fj {
  background-image: url(/static/media/fj.3e230d8e.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(/static/media/fj.7cc3769d.svg);
}
.flag-icon-fk {
  background-image: url(/static/media/fk.dd7bc357.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(/static/media/fk.2d1b653b.svg);
}
.flag-icon-fm {
  background-image: url(/static/media/fm.2b14fecb.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(/static/media/fm.3f19d612.svg);
}
.flag-icon-fo {
  background-image: url(/static/media/fo.b08620b3.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(/static/media/fo.329cbed5.svg);
}
.flag-icon-fr {
  background-image: url(/static/media/fr.b1156355.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(/static/media/fr.f8952213.svg);
}
.flag-icon-ga {
  background-image: url(/static/media/ga.29f203bb.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(/static/media/ga.33d27fe1.svg);
}
.flag-icon-gb {
  background-image: url(/static/media/gb.4f1b7af5.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(/static/media/gb.50f7fd14.svg);
}
.flag-icon-gd {
  background-image: url(/static/media/gd.b402c8c6.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(/static/media/gd.3c05bb49.svg);
}
.flag-icon-ge {
  background-image: url(/static/media/ge.292fa458.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(/static/media/ge.5f68e7f3.svg);
}
.flag-icon-gf {
  background-image: url(/static/media/gf.2ab15edf.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(/static/media/gf.23d8b419.svg);
}
.flag-icon-gg {
  background-image: url(/static/media/gg.98f67a6f.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(/static/media/gg.357e1e33.svg);
}
.flag-icon-gh {
  background-image: url(/static/media/gh.caedb912.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(/static/media/gh.77872d15.svg);
}
.flag-icon-gi {
  background-image: url(/static/media/gi.e829d66e.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(/static/media/gi.6ecace18.svg);
}
.flag-icon-gl {
  background-image: url(/static/media/gl.28a07cff.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(/static/media/gl.28bf64e4.svg);
}
.flag-icon-gm {
  background-image: url(/static/media/gm.f06a98cd.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(/static/media/gm.414139d5.svg);
}
.flag-icon-gn {
  background-image: url(/static/media/gn.36a3e9a3.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(/static/media/gn.1ce64523.svg);
}
.flag-icon-gp {
  background-image: url(/static/media/gp.fa4cab3e.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(/static/media/gp.c2c4da0e.svg);
}
.flag-icon-gq {
  background-image: url(/static/media/gq.385ac47e.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(/static/media/gq.0c1057b3.svg);
}
.flag-icon-gr {
  background-image: url(/static/media/gr.db77f48c.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(/static/media/gr.6911d460.svg);
}
.flag-icon-gs {
  background-image: url(/static/media/gs.175a61d9.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(/static/media/gs.1def7829.svg);
}
.flag-icon-gt {
  background-image: url(/static/media/gt.58f35af6.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(/static/media/gt.7fe64e01.svg);
}
.flag-icon-gu {
  background-image: url(/static/media/gu.d0da25e7.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(/static/media/gu.243ab68e.svg);
}
.flag-icon-gw {
  background-image: url(/static/media/gw.c1e88a91.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(/static/media/gw.5ecbd93c.svg);
}
.flag-icon-gy {
  background-image: url(/static/media/gy.79fcf270.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(/static/media/gy.0653b318.svg);
}
.flag-icon-hk {
  background-image: url(/static/media/hk.9502d716.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(/static/media/hk.e6716915.svg);
}
.flag-icon-hm {
  background-image: url(/static/media/hm.fe514431.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(/static/media/hm.fc838ac0.svg);
}
.flag-icon-hn {
  background-image: url(/static/media/hn.9b9bee13.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(/static/media/hn.c94622ad.svg);
}
.flag-icon-hr {
  background-image: url(/static/media/hr.8f6905ab.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(/static/media/hr.d01a1866.svg);
}
.flag-icon-ht {
  background-image: url(/static/media/ht.a0b173aa.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(/static/media/ht.18b350ac.svg);
}
.flag-icon-hu {
  background-image: url(/static/media/hu.0d7409f8.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(/static/media/hu.e5e334fd.svg);
}
.flag-icon-id {
  background-image: url(/static/media/id.17b99676.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(/static/media/id.9f708fe5.svg);
}
.flag-icon-ie {
  background-image: url(/static/media/ie.c68ff961.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(/static/media/ie.798a56e0.svg);
}
.flag-icon-il {
  background-image: url(/static/media/il.f62b32f0.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(/static/media/il.c36a011d.svg);
}
.flag-icon-im {
  background-image: url(/static/media/im.ed29d9ff.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(/static/media/im.6cf57263.svg);
}
.flag-icon-in {
  background-image: url(/static/media/in.e4ab7bd0.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(/static/media/in.209ae8e9.svg);
}
.flag-icon-io {
  background-image: url(/static/media/io.550553a7.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(/static/media/io.2d90626c.svg);
}
.flag-icon-iq {
  background-image: url(/static/media/iq.30dee028.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(/static/media/iq.e1922026.svg);
}
.flag-icon-ir {
  background-image: url(/static/media/ir.9023419c.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(/static/media/ir.717422e6.svg);
}
.flag-icon-is {
  background-image: url(/static/media/is.cff140f4.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(/static/media/is.ae44c07e.svg);
}
.flag-icon-it {
  background-image: url(/static/media/it.8d15de04.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(/static/media/it.22b99ae7.svg);
}
.flag-icon-je {
  background-image: url(/static/media/je.2026b139.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(/static/media/je.862cd38b.svg);
}
.flag-icon-jm {
  background-image: url(/static/media/jm.67f96b2f.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(/static/media/jm.b7b13124.svg);
}
.flag-icon-jo {
  background-image: url(/static/media/jo.118c5546.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(/static/media/jo.51302798.svg);
}
.flag-icon-jp {
  background-image: url(/static/media/jp.95c2abfe.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(/static/media/jp.ae89446a.svg);
}
.flag-icon-ke {
  background-image: url(/static/media/ke.0bbfd051.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(/static/media/ke.1c54a489.svg);
}
.flag-icon-kg {
  background-image: url(/static/media/kg.e588babc.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(/static/media/kg.c6895000.svg);
}
.flag-icon-kh {
  background-image: url(/static/media/kh.7f27fa73.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(/static/media/kh.25b4be5d.svg);
}
.flag-icon-ki {
  background-image: url(/static/media/ki.bf675826.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(/static/media/ki.ca42f7e3.svg);
}
.flag-icon-km {
  background-image: url(/static/media/km.0f12d30c.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(/static/media/km.e0df62e4.svg);
}
.flag-icon-kn {
  background-image: url(/static/media/kn.b0fd5e10.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(/static/media/kn.4ad12564.svg);
}
.flag-icon-kp {
  background-image: url(/static/media/kp.07ebeb5c.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(/static/media/kp.f08daf33.svg);
}
.flag-icon-kr {
  background-image: url(/static/media/kr.6d3d963f.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(/static/media/kr.68586ef8.svg);
}
.flag-icon-kw {
  background-image: url(/static/media/kw.0d0ac54c.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(/static/media/kw.33b3292e.svg);
}
.flag-icon-ky {
  background-image: url(/static/media/ky.93a85a55.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(/static/media/ky.7a1c98fc.svg);
}
.flag-icon-kz {
  background-image: url(/static/media/kz.d37cbd61.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(/static/media/kz.156c054b.svg);
}
.flag-icon-la {
  background-image: url(/static/media/la.b61549ab.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(/static/media/la.562dda74.svg);
}
.flag-icon-lb {
  background-image: url(/static/media/lb.23f85946.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(/static/media/lb.8e9c186c.svg);
}
.flag-icon-lc {
  background-image: url(/static/media/lc.1c3a5554.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(/static/media/lc.c056c2a7.svg);
}
.flag-icon-li {
  background-image: url(/static/media/li.9e40c74a.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(/static/media/li.7787a5f8.svg);
}
.flag-icon-lk {
  background-image: url(/static/media/lk.96cf4c4f.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(/static/media/lk.bf15b308.svg);
}
.flag-icon-lr {
  background-image: url(/static/media/lr.039251e3.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(/static/media/lr.6656f943.svg);
}
.flag-icon-ls {
  background-image: url(/static/media/ls.c0799ebf.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(/static/media/ls.533cb320.svg);
}
.flag-icon-lt {
  background-image: url(/static/media/lt.c3aeac0d.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(/static/media/lt.70975be0.svg);
}
.flag-icon-lu {
  background-image: url(/static/media/lu.c858787c.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(/static/media/lu.2585715a.svg);
}
.flag-icon-lv {
  background-image: url(/static/media/lv.8b293d98.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(/static/media/lv.f3c1274d.svg);
}
.flag-icon-ly {
  background-image: url(/static/media/ly.ae438f5a.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(/static/media/ly.8b64bcbd.svg);
}
.flag-icon-ma {
  background-image: url(/static/media/ma.60fbc221.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(/static/media/ma.bee9c054.svg);
}
.flag-icon-mc {
  background-image: url(/static/media/mc.b4f4b90d.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(/static/media/mc.78528abe.svg);
}
.flag-icon-md {
  background-image: url(/static/media/md.8f2e0071.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(/static/media/md.63bbfb2e.svg);
}
.flag-icon-me {
  background-image: url(/static/media/me.8096e4aa.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(/static/media/me.a0dbae6f.svg);
}
.flag-icon-mf {
  background-image: url(/static/media/mf.5b9ff36c.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(/static/media/mf.487f7bd7.svg);
}
.flag-icon-mg {
  background-image: url(/static/media/mg.67f5922d.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(/static/media/mg.91e10ba0.svg);
}
.flag-icon-mh {
  background-image: url(/static/media/mh.6d60cee3.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(/static/media/mh.8f1f9134.svg);
}
.flag-icon-mk {
  background-image: url(/static/media/mk.ed091b88.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(/static/media/mk.2413b107.svg);
}
.flag-icon-ml {
  background-image: url(/static/media/ml.e6f097f9.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(/static/media/ml.204b0da4.svg);
}
.flag-icon-mm {
  background-image: url(/static/media/mm.92e9f832.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(/static/media/mm.8d6d26bc.svg);
}
.flag-icon-mn {
  background-image: url(/static/media/mn.b6529a3b.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(/static/media/mn.39952937.svg);
}
.flag-icon-mo {
  background-image: url(/static/media/mo.44f9c555.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(/static/media/mo.1f249f51.svg);
}
.flag-icon-mp {
  background-image: url(/static/media/mp.aa6f2a40.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(/static/media/mp.2671eebe.svg);
}
.flag-icon-mq {
  background-image: url(/static/media/mq.bfeadb02.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(/static/media/mq.a09e4865.svg);
}
.flag-icon-mr {
  background-image: url(/static/media/mr.a3c31876.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(/static/media/mr.b293ed89.svg);
}
.flag-icon-ms {
  background-image: url(/static/media/ms.d5390a0c.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(/static/media/ms.058b028b.svg);
}
.flag-icon-mt {
  background-image: url(/static/media/mt.51f074ae.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(/static/media/mt.fd857604.svg);
}
.flag-icon-mu {
  background-image: url(/static/media/mu.896330b7.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(/static/media/mu.67c8f362.svg);
}
.flag-icon-mv {
  background-image: url(/static/media/mv.3c896bfd.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(/static/media/mv.0fdc08c6.svg);
}
.flag-icon-mw {
  background-image: url(/static/media/mw.59519962.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(/static/media/mw.290d49f1.svg);
}
.flag-icon-mx {
  background-image: url(/static/media/mx.fc563797.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(/static/media/mx.cb47c8bf.svg);
}
.flag-icon-my {
  background-image: url(/static/media/my.c52af28e.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(/static/media/my.5991a60d.svg);
}
.flag-icon-mz {
  background-image: url(/static/media/mz.1044789c.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(/static/media/mz.e8801c33.svg);
}
.flag-icon-na {
  background-image: url(/static/media/na.05f127b0.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(/static/media/na.2a5f6f2b.svg);
}
.flag-icon-nc {
  background-image: url(/static/media/nc.b6c1c6b3.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(/static/media/nc.0dba674e.svg);
}
.flag-icon-ne {
  background-image: url(/static/media/ne.b7369ec7.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(/static/media/ne.e56edd30.svg);
}
.flag-icon-nf {
  background-image: url(/static/media/nf.82cb457a.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(/static/media/nf.8f8df7a2.svg);
}
.flag-icon-ng {
  background-image: url(/static/media/ng.992459a3.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(/static/media/ng.520463e1.svg);
}
.flag-icon-ni {
  background-image: url(/static/media/ni.b828dceb.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(/static/media/ni.c90e4163.svg);
}
.flag-icon-nl {
  background-image: url(/static/media/nl.e336d50a.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(/static/media/nl.9bfd784c.svg);
}
.flag-icon-no {
  background-image: url(/static/media/no.0b41df77.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(/static/media/no.b7a21f54.svg);
}
.flag-icon-np {
  background-image: url(/static/media/np.3ce0600c.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(/static/media/np.a754e9e8.svg);
}
.flag-icon-nr {
  background-image: url(/static/media/nr.34ed2f24.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(/static/media/nr.2ef5b7c8.svg);
}
.flag-icon-nu {
  background-image: url(/static/media/nu.caaabfca.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(/static/media/nu.4a4641b3.svg);
}
.flag-icon-nz {
  background-image: url(/static/media/nz.a8f100c7.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(/static/media/nz.380c4a3a.svg);
}
.flag-icon-om {
  background-image: url(/static/media/om.7332c94c.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(/static/media/om.397d3f2b.svg);
}
.flag-icon-pa {
  background-image: url(/static/media/pa.0d16b0e8.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(/static/media/pa.beb40ab6.svg);
}
.flag-icon-pe {
  background-image: url(/static/media/pe.4fb8c006.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(/static/media/pe.3777e89e.svg);
}
.flag-icon-pf {
  background-image: url(/static/media/pf.8e525621.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(/static/media/pf.a68f0f63.svg);
}
.flag-icon-pg {
  background-image: url(/static/media/pg.9b228e6c.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(/static/media/pg.e3c208b9.svg);
}
.flag-icon-ph {
  background-image: url(/static/media/ph.4e44ea60.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(/static/media/ph.03c78e38.svg);
}
.flag-icon-pk {
  background-image: url(/static/media/pk.3238f8e2.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(/static/media/pk.3a126471.svg);
}
.flag-icon-pl {
  background-image: url(/static/media/pl.562edca5.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(/static/media/pl.3fe3bd51.svg);
}
.flag-icon-pm {
  background-image: url(/static/media/pm.1e97e8d7.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(/static/media/pm.89993b1f.svg);
}
.flag-icon-pn {
  background-image: url(/static/media/pn.2d4ce209.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(/static/media/pn.3b9532b7.svg);
}
.flag-icon-pr {
  background-image: url(/static/media/pr.b55721a5.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(/static/media/pr.1d278b02.svg);
}
.flag-icon-ps {
  background-image: url(/static/media/ps.07005a7f.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(/static/media/ps.2992f9b9.svg);
}
.flag-icon-pt {
  background-image: url(/static/media/pt.c31a6c49.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(/static/media/pt.09cd4ef9.svg);
}
.flag-icon-pw {
  background-image: url(/static/media/pw.005061a1.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(/static/media/pw.8207f624.svg);
}
.flag-icon-py {
  background-image: url(/static/media/py.13233f64.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(/static/media/py.c5bbbec1.svg);
}
.flag-icon-qa {
  background-image: url(/static/media/qa.b314986b.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(/static/media/qa.78909a6f.svg);
}
.flag-icon-re {
  background-image: url(/static/media/re.17909e37.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(/static/media/re.01fea3b6.svg);
}
.flag-icon-ro {
  background-image: url(/static/media/ro.625aca9e.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(/static/media/ro.22278e13.svg);
}
.flag-icon-rs {
  background-image: url(/static/media/rs.0b9d64b2.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(/static/media/rs.14899009.svg);
}
.flag-icon-ru {
  background-image: url(/static/media/ru.0cacf46e.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(/static/media/ru.e3ee3b09.svg);
}
.flag-icon-rw {
  background-image: url(/static/media/rw.7fe5146b.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(/static/media/rw.997fe41b.svg);
}
.flag-icon-sa {
  background-image: url(/static/media/sa.01b40f18.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(/static/media/sa.02710800.svg);
}
.flag-icon-sb {
  background-image: url(/static/media/sb.c23eab6d.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(/static/media/sb.d64e9848.svg);
}
.flag-icon-sc {
  background-image: url(/static/media/sc.30759b7a.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(/static/media/sc.ad1bcb4c.svg);
}
.flag-icon-sd {
  background-image: url(/static/media/sd.9b0974f1.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(/static/media/sd.7ab061d8.svg);
}
.flag-icon-se {
  background-image: url(/static/media/se.fe725901.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(/static/media/se.b039bdb8.svg);
}
.flag-icon-sg {
  background-image: url(/static/media/sg.45fb3666.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(/static/media/sg.ae32bb35.svg);
}
.flag-icon-sh {
  background-image: url(/static/media/sh.73751467.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(/static/media/sh.50626aa7.svg);
}
.flag-icon-si {
  background-image: url(/static/media/si.fb87a786.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(/static/media/si.8be67718.svg);
}
.flag-icon-sj {
  background-image: url(/static/media/sj.ae547dbe.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(/static/media/sj.ecbc9e93.svg);
}
.flag-icon-sk {
  background-image: url(/static/media/sk.1da1c0ab.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(/static/media/sk.e7c5539e.svg);
}
.flag-icon-sl {
  background-image: url(/static/media/sl.ddbd1d9b.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(/static/media/sl.f6315f74.svg);
}
.flag-icon-sm {
  background-image: url(/static/media/sm.f1d07953.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(/static/media/sm.9591e46a.svg);
}
.flag-icon-sn {
  background-image: url(/static/media/sn.5b654e1a.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(/static/media/sn.d2bec7ef.svg);
}
.flag-icon-so {
  background-image: url(/static/media/so.28889c60.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(/static/media/so.c1561217.svg);
}
.flag-icon-sr {
  background-image: url(/static/media/sr.788f3e2a.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(/static/media/sr.be27d1ae.svg);
}
.flag-icon-ss {
  background-image: url(/static/media/ss.67001d2a.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(/static/media/ss.e3933b44.svg);
}
.flag-icon-st {
  background-image: url(/static/media/st.1f545eb9.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(/static/media/st.d0a56dbb.svg);
}
.flag-icon-sv {
  background-image: url(/static/media/sv.46858d61.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(/static/media/sv.4dd6d709.svg);
}
.flag-icon-sx {
  background-image: url(/static/media/sx.73a3a178.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(/static/media/sx.78876392.svg);
}
.flag-icon-sy {
  background-image: url(/static/media/sy.64f0d2d7.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(/static/media/sy.73690f50.svg);
}
.flag-icon-sz {
  background-image: url(/static/media/sz.fa1a994d.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(/static/media/sz.1e261ba0.svg);
}
.flag-icon-tc {
  background-image: url(/static/media/tc.c61ef06a.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(/static/media/tc.c3867f8d.svg);
}
.flag-icon-td {
  background-image: url(/static/media/td.a0923ddc.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(/static/media/td.f37a395c.svg);
}
.flag-icon-tf {
  background-image: url(/static/media/tf.4ab43cc9.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(/static/media/tf.2e7dc1af.svg);
}
.flag-icon-tg {
  background-image: url(/static/media/tg.e602a907.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(/static/media/tg.29fa137c.svg);
}
.flag-icon-th {
  background-image: url(/static/media/th.76fca72f.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(/static/media/th.904dd785.svg);
}
.flag-icon-tj {
  background-image: url(/static/media/tj.9853139b.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(/static/media/tj.09418f13.svg);
}
.flag-icon-tk {
  background-image: url(/static/media/tk.1959d9de.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(/static/media/tk.7aaccddb.svg);
}
.flag-icon-tl {
  background-image: url(/static/media/tl.7942bccb.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(/static/media/tl.0616faaa.svg);
}
.flag-icon-tm {
  background-image: url(/static/media/tm.bdd29cb9.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(/static/media/tm.64aa750d.svg);
}
.flag-icon-tn {
  background-image: url(/static/media/tn.89a384a0.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(/static/media/tn.440d3505.svg);
}
.flag-icon-to {
  background-image: url(/static/media/to.79354e72.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(/static/media/to.238ef1cd.svg);
}
.flag-icon-tr {
  background-image: url(/static/media/tr.ed6d5f37.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(/static/media/tr.ce2e2e8e.svg);
}
.flag-icon-tt {
  background-image: url(/static/media/tt.c3647d9b.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(/static/media/tt.27c618af.svg);
}
.flag-icon-tv {
  background-image: url(/static/media/tv.d78bd31e.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(/static/media/tv.f4b7afec.svg);
}
.flag-icon-tw {
  background-image: url(/static/media/tw.21291ae9.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(/static/media/tw.eb5ac137.svg);
}
.flag-icon-tz {
  background-image: url(/static/media/tz.d3df42da.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(/static/media/tz.d02545a1.svg);
}
.flag-icon-ua {
  background-image: url(/static/media/ua.a8b13525.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(/static/media/ua.841d259d.svg);
}
.flag-icon-ug {
  background-image: url(/static/media/ug.278e456e.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(/static/media/ug.1c8fcdc4.svg);
}
.flag-icon-um {
  background-image: url(/static/media/um.05615112.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(/static/media/um.3d347682.svg);
}
.flag-icon-us {
  background-image: url(/static/media/us.ae656592.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(/static/media/us.8ec58318.svg);
}
.flag-icon-uy {
  background-image: url(/static/media/uy.79b02850.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(/static/media/uy.adbc4992.svg);
}
.flag-icon-uz {
  background-image: url(/static/media/uz.eb1e00b8.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(/static/media/uz.ca892343.svg);
}
.flag-icon-va {
  background-image: url(/static/media/va.0e3b3cc1.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(/static/media/va.56a808f7.svg);
}
.flag-icon-vc {
  background-image: url(/static/media/vc.4ac5124f.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(/static/media/vc.bbb52fa0.svg);
}
.flag-icon-ve {
  background-image: url(/static/media/ve.b2cd5a9a.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(/static/media/ve.9f23d962.svg);
}
.flag-icon-vg {
  background-image: url(/static/media/vg.e1c461f7.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(/static/media/vg.68763b7f.svg);
}
.flag-icon-vi {
  background-image: url(/static/media/vi.9af6bfa6.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(/static/media/vi.b41b18d9.svg);
}
.flag-icon-vn {
  background-image: url(/static/media/vn.a62ad62f.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(/static/media/vn.a0081482.svg);
}
.flag-icon-vu {
  background-image: url(/static/media/vu.5c33b1e7.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(/static/media/vu.9b6cbbe9.svg);
}
.flag-icon-wf {
  background-image: url(/static/media/wf.e3ac728c.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(/static/media/wf.05522b9f.svg);
}
.flag-icon-ws {
  background-image: url(/static/media/ws.405a2c5f.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(/static/media/ws.3ea6d44f.svg);
}
.flag-icon-ye {
  background-image: url(/static/media/ye.b5840a84.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(/static/media/ye.d13e1629.svg);
}
.flag-icon-yt {
  background-image: url(/static/media/yt.f06d254d.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(/static/media/yt.b6042b9c.svg);
}
.flag-icon-za {
  background-image: url(/static/media/za.70a290af.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(/static/media/za.67ff2e10.svg);
}
.flag-icon-zm {
  background-image: url(/static/media/zm.b7864d5c.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(/static/media/zm.71bdc28b.svg);
}
.flag-icon-zw {
  background-image: url(/static/media/zw.a21f533d.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(/static/media/zw.ad4ad36d.svg);
}
.flag-icon-es-ct {
  background-image: url(/static/media/es-ct.46e9ce9e.svg);
}
.flag-icon-es-ct.flag-icon-squared {
  background-image: url(/static/media/es-ct.9429ea9c.svg);
}
.flag-icon-eu {
  background-image: url(/static/media/eu.ee7f4712.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(/static/media/eu.4c73f57c.svg);
}
.flag-icon-gb-eng {
  background-image: url(/static/media/gb-eng.14167f77.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(/static/media/gb-eng.eabfeadc.svg);
}
.flag-icon-gb-nir {
  background-image: url(/static/media/gb-nir.5b44fea7.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(/static/media/gb-nir.42b69bbd.svg);
}
.flag-icon-gb-sct {
  background-image: url(/static/media/gb-sct.4c2c379f.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(/static/media/gb-sct.31ef8bcf.svg);
}
.flag-icon-gb-wls {
  background-image: url(/static/media/gb-wls.8742b3d6.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(/static/media/gb-wls.ca26c224.svg);
}
.flag-icon-un {
  background-image: url(/static/media/un.3835716f.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(/static/media/un.c366cfa8.svg);
}
.flag-icon-xk {
  background-image: url(/static/media/xk.de260207.svg);
}
.flag-icon-xk.flag-icon-squared {
  background-image: url(/static/media/xk.2750d8e7.svg);
}

:root{
  --font-sizes:lg, 2x, 3x, 4x, 5x;
  --font-sizes-values:1.33333333em, 2em, 3em, 4em, 5em;
  --rotates:30, 60, 90, 180, 270;
}

.flag-icon-flip-horizontal{
  -webkit-transform:scale(-1, 1);
          transform:scale(-1, 1);
}

.flag-icon-flip-vertical{
  -webkit-transform:scale(1, -1);
          transform:scale(1, -1);
}

.flag-icon-lg{
    font-size:1.33333333em;
  }

.flag-icon-2x{
    font-size:2em;
  }

.flag-icon-3x{
    font-size:3em;
  }

.flag-icon-4x{
    font-size:4em;
  }

.flag-icon-5x{
    font-size:5em;
  }

.flag-icon-rotate-30{
    -webkit-transform:rotate(30deg);
            transform:rotate(30deg);
  }

.flag-icon-rotate-60{
    -webkit-transform:rotate(60deg);
            transform:rotate(60deg);
  }

.flag-icon-rotate-90{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg);
  }

.flag-icon-rotate-180{
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg);
  }

.flag-icon-rotate-270{
    -webkit-transform:rotate(270deg);
            transform:rotate(270deg);
  }

